import * as React from "react";
import { useEffect, useState } from "react";
import noImage from "../../Assets/images/no-image.jpg";
import "./nftmoreinfo.scss";
import { toast } from "react-toastify";
import ModalImage from "../ModalImage/ModalImage";
import {
  approveNFTWithCollection,
  approveNFTWithoutCollection,
  getModerationCollectionItem,
  getModerationNFT, rejectNFTWithCollection, rejectNFTWithoutCollection,
  getCollectionById
} from "../../services/serverRequest";


export default function NftMoreInfo({ nft, onClick, onReject }) {
  const [collection, setCollection] = useState([]);
  useEffect(() => {
    if (nft.collection_id) {
      getModerationCollectionItem(nft._id)
          .then((data) => {
          })
          .catch((error) => {});
    } else {
      getModerationNFT(nft._id)
          .then((data) => {
          })
          .catch((error) => {});
    }
  }, [nft._id]);
  const handleBackClick = () => {
    onClick();
  };
  useEffect(() => {
    const fetchCollection = async () => {
        if (nft.collection_id) {
            const fetchedCollection = await getCollectionById(nft.collection_id);
            setCollection(fetchedCollection);
        }
    };
    fetchCollection();
}, [nft.collection_id]);
  const approveNft = (nft) => {
    if (nft.collection_id) {
      approveNFTWithCollection(nft._id,{
        status: "approved",
      })
          .then((data) => {
              toast.success("Successfully approved Nft");
              onReject(nft._id);
              handleBackClick();
          })
          .catch((error) => {
              console.error("Error approving NFT with collection:", error);
          });

    } else {
      approveNFTWithoutCollection(nft._id,{
        status: "approved",
      })
          .then((data) => {
              toast.success("Successfully approved Nft");
              onReject(nft._id);
              handleBackClick();
          })
          .catch((error) => {
               console.error("Error approving NFT:", error);
          });

    }
  };


  const rejectNft = (nft) => {
    if (nft.collection_id) {
      rejectNFTWithCollection(nft._id,{ status: "reject" })
          .then((data) => {
            toast.error("Successfully rejected collection");
            onReject(nft._id);
            handleBackClick();
          })
          .catch((error) => {
            console.error("Error rejecting NFT:", error);
          });

    } else {
      rejectNFTWithoutCollection(nft._id,{
        status: "reject",
      })
          .then((data) => {
        toast.error("Successfully rejected collection");
        onReject(nft._id);
        handleBackClick();
      })
          .catch((error) => {
            console.error("Error rejecting NFT:", error);
          });

    }
  };


  return (
    <div className="nftInfo">
      <div className="nftInfo__container">
        <button className="nftInfo__container_back" onClick={handleBackClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            height="20px"
            version="1.1"
            viewBox="0 0 512 512"
            width="20px"
          >
            <polygon
              points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256"
              style={{ fill: "white" }}
            />
          </svg>
          <p>Back</p>
        </button>
        <h2>{nft.name}</h2>
        <h4>Creator: {nft.user_address}</h4>
        <div className="nftInfo__box">
          {/*<img src={nft.image?.fileUrl} alt="" />*/}

          <ModalImage nft={nft} imageSrc={nft.image?.fileUrl || noImage} altText="X Image" />

          <div className="nftInfo__box_right">
            <div className="nftInfo__box_right_top">
              <h4 className="nftInfo__box_right_top_h4">Contract</h4>
              <div className="nftInfo__box_right_top_content">
                <p className="nftInfo__box_right_top_content_p">
                  Category: {nft.categoryTitle ? nft.categoryTitle : "-"}
                </p>
                <p className="nftInfo__box_right_top_content_p">
                <a href={`https://tribeton.io/collection/${collection._id}`}>
                  Collection: {collection.name ? collection.name : "-"}
                  </a>
                 
                </p>
                <p
                  className="nftInfo__box_right_top_content_p"
                  style={{ wordWrap: "break-word" }}
                >
                  Description: {nft.description ? nft.description : "-"}
                </p>
              </div>
            </div>
            <div className="nftInfo__box_right_bottom">
              <p className="nftInfo__box_right_bottom_h4">Social networks:</p>
              <div className="nftInfo__box_right_top_content">
                <p className="nftInfo__box_right_top_content_p">Telegram: <a
                    target='_blank'
                    href={`https://t.me/${collection.telegram}`}>{collection.telegram ? collection.telegram : "-"}</a></p>
                <p className="nftInfo__box_right_top_content_p">YouTube: <a
                    target='_blank'
                    href={`https://www.youtube.com/${collection.youtube}`}>{collection.youtube ? collection.youtube : "-"}</a></p>
                <p className="nftInfo__box_right_top_content_p">Discord: <a
                    target='_blank'
                    href={`https://discord.gg/@${collection.discord}`}>{collection.discord ? collection.discord : "-"}</a>
                </p>
                <p className="nftInfo__box_right_top_content_p">X: <a
                    target='_blank'
                    href={`https://x.com/${collection.twitter}`}>{collection.twitter ? collection.twitter : "-"}</a></p>
                {/* <p className="nftInfo__box_right_top_content_p">Token ID: -</p> */}
                {/* <p className="nftInfo__box_right_top_content_p">
                  Transaction Hash:
                </p>
                <p className="nftInfo__box_right_top_content_p">-</p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="nftInfo__btns">
          <button
              onClick={() => {
                approveNft(nft);
              }}
              className="nftInfo__greenBtn"
          >
            Approve
          </button>
          <button
              onClick={() => {
                rejectNft(nft);
              }}
              className="nftInfo__redBtn"
          >
            Refuse
          </button>
        </div>
      </div>
    </div>
  );
}
