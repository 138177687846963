import React, {useEffect, useState} from "react";
import "./blog.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Navbar from "../../Components/Navbar/Navbar";
import { ModalWindowsCreateBlog } from "../../Components/modalWindowCreateBlog/ModalWindowsCreateBlog";
import ModalImage from "../../Components/ModalImage/ModalImage";
import {deleteBlogById, getBlogs} from "../../services/serverRequest";

const Blog = () => {
  const [modalActive, setModalActive] = useState(false);
  const [articles, setArticles] = useState([]);
  const [activeArticles, setActiveArticles] = useState('');

    useEffect(() => {
        getBlogs().then((data) => {
            setArticles(data.data);
        }).catch((error) => {
            console.error('Error fetching blogs:', error);
        });
    }, []);

    const deleteBlog = (id) =>{
        deleteBlogById(id).then( () => {
            getBlogs().then((data) => {
                setArticles(data.data);
            }).catch((error) => {
                console.error('Error fetching blogs:', error);
            });
        })
    }
    const refreshBlogs = () => {
        getBlogs().then((data) => {
            setArticles(data.data);
        }).catch((error) => {
            console.error('Error fetching blogs:', error);
        });
    };


  return (
    <div className="blog">
      <Sidebar />
      <div className="blog__containr">
      <Navbar />
        <div className="table__container">
          
          <div className="table__title">
            <h3>All Categories</h3>
            <div className="table__title-buttons">
              <div className="search">
                <input type="text" placeholder="Search" />
                <span>
                  <SearchOutlinedIcon />
                </span>
              </div>
              <button
                className="open-modal-form"
                onClick={() => setModalActive(true)}
              >
                + Add New
              </button>
            </div>
          </div>
          <TableContainer
            sx={{ margin: "0 auto", border: 0 }}
            component={Paper}
          >
            <Table
              sx={{ width: "100%" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead
                sx={{
                  backgroundColor: "#4634ff",
                  fontFamily: '"Poppins", sans-serif;',
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                <TableRow sx={{ width: "100%" }}>
                  <TableCell
                    sx={{
                      border: "none",
                      color: "white",
                      fontFamily: '"Poppins", sans-serif;',
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "38%",
                      color: "white",
                      fontFamily: '"Poppins", sans-serif;',
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                    align="center"
                  >
                    Short Description
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "20%",
                      color: "white",
                      fontFamily: '"Poppins", sans-serif;',
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                    align="center"
                  >
                    Image
                  </TableCell>
                  <TableCell
                      sx={{
                          width: "20%",
                          color: "white",
                          fontFamily: '"Poppins", sans-serif;',
                          fontWeight: "600",
                          fontSize: "12px",
                      }}
                      align="center"
                  >
                      Status
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      color: "white",
                      fontFamily: '"Poppins", sans-serif;',
                      fontWeight: "600",
                      fontSize: "12px",
                    }}
                    align="right"
                  >
                    Featured
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {articles.map((article) => {
                  return (
                    <TableRow
                      key={article._id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        sx={{
                          color: "#5b6e88",
                          fontFamily: '"Poppins", sans-serif;',
                          fontWeight: "500",
                          fontSize: "13px",
                        }}
                        component="th"
                        scope="row"
                      >
                        {article.title}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#5b6e88",
                          fontFamily: '"Poppins", sans-serif;',
                          fontWeight: "500",
                          fontSize: "13px",
                          padding: "15px",
                        }}
                        align="center"
                      >
                        <p className="blogText" dangerouslySetInnerHTML={{ __html: article.text }}/>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: '"Poppins", sans-serif;',
                          fontWeight: "500",
                          fontSize: "13px",
                        }}
                        align="center"
                      >
                        <div className="featured_blog">
                          <ModalImage
                            imageSrc={article?.image?.fileUrl}
                            altText={article.title}
                            imgClass={"imgClassBlog"}
                          />
                        </div>
                      </TableCell>
                        <TableCell
                            sx={{
                                fontFamily: '"Poppins", sans-serif;',
                                fontWeight: "500",
                                fontSize: "13px",
                            }}
                            align="center"
                        >
                            <button className="deleteBlogButton" onClick={()=>{deleteBlog(article._id)}}>Delete</button>
                            <br/>
                            <br/>
                            <button className="RedactBlogButton"   onClick={() => {
                                setModalActive(true);
                                setActiveArticles(article._id);
                            }}>Update</button>
                        </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <ModalWindowsCreateBlog
            active={modalActive}
            setActive={setModalActive}
          />
            {modalActive && activeArticles && (
                <ModalWindowsCreateBlog
                    active={modalActive}
                    setActive={setModalActive}
                    update={true}
                    articleId={activeArticles}
                    onBlogUpdate={refreshBlogs}
                />
            )}

            {/*<ModalWindowCategory active={modalActiveCategory} setActive={setModalActiveCategory} categoryTitle={title}/>*/}
        </div>
      </div>
    </div>
  );
};

export default Blog;
