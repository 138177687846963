import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import '../../NFTList/nftlist.scss';
import axios from 'axios';
import NFTItem from '../../NFTItem/NFTItem';
import { BASE_URL } from '../../../services/baseURL';
import NftAwaitMore from './NftWaitMore';

const fetchAllItems = async (url, allItems = [], page = 1) => {
    try {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        const response = await axios.get(`${url}?page=${page}`, {headers});
        const data = response.data.data;
        
        // Check if there are more items to fetch
        if (data.length > 0) {
            return fetchAllItems(url, [...allItems, ...data], page + 1);
        } else {
            return allItems;
        }
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        throw error;
    }
};

const NftWait = () => {
    const [nftList, setNftList] = useState([]);
    const [nft, setNft] = useState(null);
    const [isNftItemClicked, setIsNftItemClicked] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const nftData = await fetchAllItems(`${BASE_URL}/api/admin/nfts-await`);
                // const collectionItemData = await fetchAllItems(`${BASE_URL}/api/moderation/collection-items`);
                
                const combinedData = [...nftData];
                setNftList(combinedData);
                // console.log(combinedData);
            } catch (error) {
                setNftList(false);
            }
        };

        fetchData();
    }, []);

    const handleNftItemClick = useCallback((nft) => {
        setNft(nft);
        setIsNftItemClicked(true);
    }, []);

    const handleNftMoreInfoFalse = useCallback(() => {
        setIsNftItemClicked(false);
    }, []);

    const handleReject = useCallback((nftId) => {
        setNftList((prevNftList) => prevNftList.filter((nft) => nft._id !== nftId));
        setIsNftItemClicked(false);
    }, []);

    return (
        <div className='nftList'>
            {!isNftItemClicked && (
                <div className="nftList__container">
                    <div className="nftList__h1">
                        <h1>NFT</h1>
                    </div>
                    <div className="nftList__items">
                        {nftList ? nftList.map((nft, index) => (
                            <NFTItem key={index} nft={nft} onClick={handleNftItemClick} />
                        )) : <div>Something went wrong (try to restart the page)</div>}
                    </div>
                </div>
            )}
            {isNftItemClicked && (
                // <div></div>
                <NftAwaitMore 
                    onClick={handleNftMoreInfoFalse} 
                    nft={nft}
                    onReject={handleReject}
                />
            )}
        </div>
    );
};

export default React.memo(NftWait);
