import axios from "axios";
import { BASE_URL } from "./baseURL";

const getHeaders = () => {
    return {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
};

export const updateSeo = async (data) => {
    try {
        const response = await axios.put(`${BASE_URL}/api/manage/seo/66a212f37f4b3ea3a45f8436`, data, {
            headers: getHeaders(),
        });
        return response.data;
    } catch (error) {
        console.error('Error updating SEO settings:', error.message);
    }
};

export const getAllSeoSetings = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/manage/allseo`, {
            headers: getHeaders(),
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching SEO settings:', error.message);
    }
};
