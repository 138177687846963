import React, { useEffect, useState, useCallback, useMemo } from "react";
import "../../../Components/NFTList/nftlist.scss";
import NFTItem from "../../../Components/NFTItem/NFTItem";
import { BASE_URL } from "../../../services/baseURL";
import ElementMoreInfo from "./ElementMoreInfo";
import { fetchAllItems } from "../../../utils/fetchAllItems";

const ActiveItems = ({ type }) => {
  const [nftList, setNftList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Кешування запитів, щоб уникнути зайвих викликів
  const cache = useMemo(() => new Map(), []);

  // Функція для отримання даних
  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      if (cache.has(type)) {
        // Якщо дані вже є в кеші, використовуємо їх
        setNftList(cache.get(type));
      } else {
        // Інакше робимо запит і зберігаємо дані в кеші
        const [nftData, disabledNftData] = await Promise.all([
          fetchAllItems(
            `${BASE_URL}/api/${
              type === "collection_items"
                ? "collection_items"
                : // : type === "nfts"
                  // ? "nft-items"
                  "collections"
            }`
          ),
          fetchAllItems(
            `${BASE_URL}/api/disable/${
              type === "collection_items"
                ? "collection_items"
                : // : type === "nfts"
                  // ? "nft-items"
                  "collections"
            }`
          ),
        ]);
        const combinedData = [...nftData, ...disabledNftData];
        setNftList(combinedData);
        cache.set(type, combinedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setNftList([]);
    } finally {
      setIsLoading(false);
    }
  }, [type, cache]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Функція для обробки кліку на елемент
  const handleNftItemClick = useCallback((item) => {
    setSelectedItem(item);
  }, []);

  // Функція для закриття деталей елемента
  const handleNftMoreInfoFalse = useCallback(() => {
    setSelectedItem(null);
  }, []);

  // Функція для видалення елемента
  const handleReject = useCallback((itemId) => {
    setNftList((prevNftList) =>
      prevNftList.filter((item) => item._id !== itemId)
    );
    setSelectedItem(null);
  }, []);

  return (
    <div className="nftList">
      {!selectedItem && (
        <div className="nftList__container">
          <div className="nftList__h1">
            <h1>
              {type === "collection_items"
                ? "Collection-items"
                : type === "nfts"
                ? "NFT"
                : "Collection"}
            </h1>
          </div>
          <div className="nftList__items">
            {isLoading ? (
              <div>Loading...</div>
            ) : nftList.length ? (
              nftList
                .filter((item) => item.status === "published")
                .map((item) => (
                  <NFTItem
                    key={item._id}
                    type={type}
                    nft={item}
                    onClick={() => handleNftItemClick(item)}
                  />
                ))
            ) : (
              <div>No items found</div>
            )}
          </div>
        </div>
      )}

      {selectedItem && (
        <ElementMoreInfo
          type={type}
          onClick={handleNftMoreInfoFalse}
          onReject={handleReject}
          item={selectedItem}
        />
      )}
    </div>
  );
};

export default React.memo(ActiveItems);
