import React, { useEffect, useState } from 'react';
import './changepricelike.scss';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Navbar from '../../Components/Navbar/Navbar';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, TextField } from "@mui/material";
import { createNewPriceEntry, updateExistingLikePriceEntry, getAllLikePrice } from "../../services/likeService";

const ChangePriceLike = () => {
    const [type, setType] = useState('CollectionItem');
    const [existingData, setExistingData] = useState([]);

    const [statusMap, setStatusMap] = useState({});

    useEffect(() => {
        getAllLikePrice().then(response => {
            setExistingData(response.data);
        });
    }, []);

    const handleChangeType = (event) => {
        setType(event.target.value);
    };

    const changePriceForLike = (itemId) => {
        const existingItem = existingData.find(item => item._id === itemId);

        if (!existingItem) return;

        const data = {
            "itemType": existingItem.itemType,
            "price": +existingItem.price,
            "ownerFee": +existingItem.ownerFee,
            "bern": +existingItem.bern,
            "userLevel": existingItem.userLevel,
            "itemLevel": existingItem.itemLevel
        };

        updateExistingLikePriceEntry(data, existingItem._id)
            .then((data) => {
                if (!data.error) {
                    updateStatus(itemId, 'success');
                } else {
                    updateStatus(itemId, 'error');
                }
            })
            .catch(() => {
                updateStatus(itemId, 'error');
            });
    };

    // Dynamic status update for each item
    const updateStatus = (itemId, newStatus) => {
        setStatusMap((prevState) => ({
            ...prevState,
            [itemId]: newStatus
        }));
    };

    const clearStatus = (itemId) => {
        updateStatus(itemId, 'default');
    };

    const renderTable = () => {
        const filteredData = existingData.filter(item => item.itemType === type);
        return (
            <table className="price-table">
                <thead>
                    <tr>
                        <th>Level</th>
                        <th>Price</th>
                        <th>Owner Fee</th>
                        <th>Burn</th>
                        <th>Fund</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item) => {
                        const fund = item.price - (+item.ownerFee + (+item.bern));
                        const itemStatus = statusMap[item._id] || 'default';

                        return (
                            <tr key={item._id}>
                                <td>{item.itemLevel}</td>
                                <td>
                                    <TextField
                                        value={item.price}
                                        onChange={(e) => handleTableInputChange(e, item._id, 'price')}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        value={item.ownerFee}
                                        onChange={(e) => handleTableInputChange(e, item._id, 'ownerFee')}
                                    />
                                </td>
                                <td>
                                    <TextField
                                        value={item.bern}
                                        onChange={(e) => handleTableInputChange(e, item._id, 'bern')}
                                    />
                                </td>
                                <td>{fund}</td>
                                <td>
                                    <Button
                                        variant="contained"
                                        color={itemStatus === 'success' ? 'success' : 'primary'}
                                        onClick={() => changePriceForLike(item._id)}
                                    >
                                        Save
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const handleTableInputChange = (event, id, field) => {
        const updatedData = existingData.map(item => {
            if (item._id === id) {
                return { ...item, [field]: event.target.value };
            }
            return item;
        });
        setExistingData(updatedData);
    };

    return (
        <div className='changePriceBar'>
            <Sidebar />
            <div className="changePriceContainer">
                <Navbar />
                <div className="changePriceLike">
                    <div className="changePriceLike_select">
                        <Box sx={{ width: 300 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    label="Type"
                                    onChange={handleChangeType}
                                >
                                    <MenuItem value={'NFTCollection'}>NFT Collection</MenuItem>
                                    <MenuItem value={'CollectionItem'}>Collection item</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>

                    {/* Render table at the bottom */}
                    <div className="changePriceLike_table">
                        {renderTable()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePriceLike;
