import React, {useEffect, useRef, useState} from 'react';
import './gallerycardnftlist.scss';
import imageGaleryDefault from '../../Assets/images/gallery_card.png'
import {LiaPencilAltSolid} from "react-icons/lia";
import {createGallery, createGalleryEllement} from "../../services/galleryService";
import logo from '../../Assets/images/logo.png'
import {toast} from "react-toastify";
const GalleryCardNFTList = React.memo(({nft, editGalleryName}) => {

    const [nftList, setNftList] = useState([]);
    const [galleryName, setGalleryName] = useState('Name gallery')
    const [galleryStatus, setGalleryStatus] = useState('')
    const [galleryElement, setGalleryElement] = useState('')
    const [galleryDescription, setGalleryDescription] = useState('Gallery description')
    const [latitude, setLatitude] = useState('')
    const [long, setLong] = useState('')
    const [avatarFile, setAvatarFile] = useState(null);
    const inputRefName = useRef(null);
    const inputRefDescription = useRef(null);

    useEffect(() => {
        if (nft && !nftList.some(existingNft => existingNft.id === nft.id)) {
            setNftList(prevList => [...prevList, nft]);
            createElementGallery()
        }
    }, [nft]);

    useEffect(() => {
        const uniqueNftList = nftList.reduce((acc, currentNft) => {
            if (!acc.some(nft => nft.id === currentNft.id)) {
                acc.push(currentNft);
            }
            return acc;
        }, []);

        if (uniqueNftList.length !== nftList.length) {
            setNftList(uniqueNftList);
        }
    }, [nftList]);
    const handleInputChangeName = (e) => {
        setGalleryName(e.target.value);
    };
    const handleInputChangeDescription = (e) => {
        setGalleryDescription(e.target.value);
    };

    const handleClickIconName = () => {
        if (inputRefName && inputRefName.current) {
            inputRefName.current.focus();
        }
    };
    
    const handleClickIconDescription = () => {
        if (inputRefDescription && inputRefDescription.current) {
            inputRefDescription.current.focus();
        }
    };
    const convertImageToBinary = () => {
        return new Promise((resolve, reject) => {
            fetch(logo) 
                .then(response => response.blob())  
                .then(blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);  
                    reader.onloadend = () => {
                        const base64data = reader.result; 
                        const binaryString = base64data.split(',')[1]; 
                        resolve(binaryString); 
                    };
                    reader.onerror = (error) => reject(error);
                })
                .catch(error => reject(error));
        });
    };

    const sendDataToServer = async () => {
        setGalleryStatus('loading gallery');
    
        const formData = new FormData();
        formData.append("nameGallery", galleryName);
        formData.append("description", galleryDescription);
        formData.append("maxElements", 10);
        formData.append("lat", latitude);
        formData.append("long", long);

        if (avatarFile) {
            formData.append("banner", avatarFile);
        } else {

            const bannerFile = new File([logo], 'banner.png');
            formData.append("banner", bannerFile);
        }
    
        try {
            const response = await createGallery(formData);
            setGalleryStatus(`Gallery "${galleryName}" successfully created`);
            toast.success('Gallery is created');
        } catch (error) {
            toast.error('Gallery is not created');
            setGalleryStatus(`Error creating gallery "${galleryName}": ${error.message}`);
        }
    };
    
    const createElementGallery = () => {
        console.log(nft)
        const elementGallery = {
            "title": nft.title,
            "owner": nft.owner ? nft.owner : '/',
            "elementUrl": nft.elementURL,
            "urlSite": nft.urlSite,
            "galleryName": editGalleryName ? editGalleryName : galleryName,
            "marketId": nft.id,
            'collectionId':nft.collectionId,
            'categoryId': nft.categoryId,
            'collectionAvatar':nft.collectionAvatar.previewUrl,
            'fileType': nft?.image?.fileType || nft.fileType
        };
        
        
        setGalleryElement(`${nft.title}: 'loading'`)
        createGalleryEllement(elementGallery)
            .then(() => {
                setGalleryElement(`${nft.title}: 'success'`)
            })
            .catch(() => {
                setGalleryElement(`${nft.title}: 'Error'`)
            })

    }


    return (
        <>
        <div className='galleryBox'>
            {!editGalleryName && (
                <>
                <div className='galleryBox__input-block'>
                    <div className='galleryBox__name'>
                        <input
                            ref={inputRefName}
                            onChange={handleInputChangeName}
                            value={galleryName}
                            className="gallery-title-h3"
                        />
                        <LiaPencilAltSolid style={{cursor: 'pointer'}} onClick={handleClickIconName} />
                    </div>
                    <div className='galleryBox__description'>
                    <input
                            value={galleryDescription}
                            ref={inputRefDescription}
                            onChange={handleInputChangeDescription} 
                        className="gallery-title-h3"
                        />
                        <LiaPencilAltSolid style={{cursor: 'pointer'}} onClick={handleClickIconDescription} />
                    </div>
                    <div className='galleryBox__avatar'>
                        <div className="custom-file-input">
                            <button onClick={() => document.getElementById('file-input').click()}
                                    className="upload-button">
                                Upload Avatar
                            </button>
                            <input
                                type="file"
                                id="file-input"
                                style={{display: 'none'}} // Hide the default input
                                onChange={(e) => setAvatarFile(e.target.files[0])}
                                accept="image/*"
                            />
                            {avatarFile && <p className="file-name">{avatarFile.name}</p>}
                        </div>
                        <input
                            onChange={(e) => setLatitude(e.target.value)}
                            placeholder={'Latitude'}
                            value={latitude}
                            className="input_default"
                        />
                        <input
                            onChange={(e) => setLong(e.target.value)}
                            value={long}
                            placeholder={'Longitude'}
                            className="input_default"
                        />

                    </div>
                </div>

                    <button className='galleryButtonCreate' onClick={sendDataToServer}>Create Gallery</button>
                </>
            )}
        </div>
            <div className="gallery-cards">
                {nftList.map((nft) => (
                    <img key={nft._id} src={nft.elementURL ? nft.elementURL : imageGaleryDefault} alt={nft.id}/>
                ))}
            </div>
            <h5 style={{fontSize: '17px', fontWeight: 500}}><br/>{galleryStatus}</h5>
            <h5 style={{fontSize: '17px', fontWeight: 500}}><br/>{galleryElement}</h5>
        </>


    );
});

export default GalleryCardNFTList;
