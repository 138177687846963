import axios from "axios";
import { BASE_URL } from "./baseURL";

const headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`
};

export const getBlogById = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/blog/${id}`, { headers });
        return response.data;
    } catch (error) {
        console.error('Error fetching blog:', error.message);
    }
}

export const updateBlogById = async (id, data) => {
    try {
        const response = await axios.put(`${BASE_URL}/api/update/blog/${id}`, data, { headers });
        return response.data;
    } catch (error) {
        console.error('Error updating blog:', error.message);
    }
}
