import React, {useEffect, useState} from 'react';
import './2faform.scss'
import {setupTwoFactor, verifyTwoFactor} from "../../services/authService";
import {toast} from "react-toastify";
import  QRCode  from 'qrcode.react';
import {useNavigate} from "react-router-dom";
const TwoFaForm = ({username,password,token}) => {
    const [twoFaCode, setTwoFaCode] = useState("");
    const navigate = useNavigate();



    // const handleTwoFactorSetup = () => {
    //     setupTwoFactor(username, password)
    //         .then(data => {
    //         })
    //         .catch(error => {
    //             console.error('Two-factor setup failed', error);
    //             toast.error('Two-factor setup failed');
    //         });
    // };



    const Login = async (e) => {
        e.preventDefault();
        try {
            const result = await verifyTwoFactor(username, twoFaCode);
            if (result.token) {
                localStorage.setItem('token', result.token)
                toast.success('Successfully logged in');
                navigate("/home");
            } else {
                toast.error('Invalid two-factor code');
            }
        } catch (error) {
            console.error("Two-factor authentication failed", error);
            toast.error('Two-factor authentication failed');
        }
    };

    // useEffect(() => {
    //     handleTwoFactorSetup()
    // }, []);

    return (
        <div>
            <div className="form-title">
                <h2>Welcome to <span>tribe ton</span></h2>
                <p>Admin Login to TRIBE TON</p>
            </div>
            <div className="form-input">
                <div className="input-box">
                    <label htmlFor="">Verify 2fa</label>
                    <input type="text"
                           required
                           value={twoFaCode}
                           onChange={(e) => setTwoFaCode(e.target.value)}
                           placeholder="Enter 2fa code"
                    />
                </div>
                <button type="submit" className="btn" onClick={Login}>Login</button>
            </div>
        </div>
    );
};

export default TwoFaForm;