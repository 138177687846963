import React, { useState , useEffect} from 'react';
import axios from 'axios';
import "./modalwindow.scss";
import {toast} from "react-toastify";
import { BASE_URL } from '../../services/baseURL';

export const ModalWindow = ({ active, setActive }) => {
  const [file, setFile] = useState(null);
  const [inputData, setInputData] = useState({ title: "", description: "" });
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleData = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };
  function refreshPage(){ 
    window.location.reload(); 
}

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', inputData.title);
    formData.append('description', inputData.description);
    if (file) {
      formData.append('photo', file);
    }

    try {
      const response = await axios.post(`${BASE_URL}/api/category`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Додаємо токен
        },
      });
      toast.success('Category is created');
      window.location.reload();
    } catch (err) {
      toast.error('Error create category');
      console.error(err);
    }
  };


  useEffect(() => {
    const userId = localStorage.getItem('_id'); // Retrieve user ID from localStorage

    if (userId) {
      const fetchProfileData = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/api/user/profile/${userId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`, // Додаємо токен
            },
          });
          setProfileData(response.data);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      };

      fetchProfileData();
    } else {
      setError(new Error('User ID not found in localStorage'));
      setLoading(false);
    }
  }, []);


  return (
    <div className={active ? "modal-window active" : "modal-window"} onClick={() => setActive(false)}>
      <div className={active ? "wrapper-form active" : "wrapper-form"} onClick={(e) => e.stopPropagation()}>
        <form onSubmit={handleSubmit} className='modal-window__form'>
          <h3>Add Category</h3>
          <div className='form-block'>
            <div className='form-input__image'>
              <span>Image</span>
              <p>250×170</p>
              <label className='label__image'>
                <span>Upload Image </span>
                <input name='photo' type="file" className='modal-window__input-file' accept='.jpeg,.jpg,.png' onChange={handleChange} />
              </label>
              {file && <ul className="file-list"><li>{file.name}</li></ul>}
              <span>Supported files: <span>jpeg, jpg, png</span> Image will be resized into 250×170 px</span>
            </div>
            <div className='form-input__text'>
              <div className='form-input__name'>
                <label>Name</label>
                <input value={inputData.title} name="title" type="text" onChange={handleData} />
              </div>
              <div className='form-input__description'>
                <label>Description</label>
                <textarea value={inputData.description} name="description" onChange={handleData}/>
              </div>
            </div>
          </div>
          <button className='modal-form-btn' type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};
