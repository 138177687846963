import axios from "axios";
import { BASE_URL } from "./baseURL";
import {toast} from "react-toastify";
const getHeaders = () => {
    return {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    };
};

export const createNewPriceEntry = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/admin/like-price`, data, {
            headers: getHeaders(),
        });
        toast.success('Price is created');
        return response.data;
    } catch (error) {
        toast.error('Price is not created');
        console.error('Error creating new price entry:', error.message);
    }
};

export const updateExistingLikePriceEntry = async (data,id) => {
    try {
        const response = await axios.put(`${BASE_URL}/api/admin/like-price/${id}`, data, {
            headers: getHeaders(),
        });
        toast.success('Price update');
        return response.data;
    } catch (error) {
        toast.error('Price update error');
        console.error('Error updating like price entry:', error.message);
    }
};

export const getAllLikePrice = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/admin/like-prices`, {
            headers: getHeaders(),
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching all like prices:', error.message);
    }
};

export const getLikePriceById = async (priceId) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/admin/like-prices/${priceId}`, {
            headers: getHeaders(),
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching like price by ID:', error.message);
    }
};
