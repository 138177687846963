import axios from "axios";

export const fetchAllItems = async (url, pageSize = 10, maxPages = 10) => {
    let allItems = [];
    let page = 1;

    try {
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };

        let response;
        do {
            response = await axios.get(`${url}?page=${page}&limit=${pageSize}`, {
                headers: headers,
            });

            const data = response.data.data;

            if (data.length > 0) {
                allItems = allItems.concat(data);
                page++;
            } else {
                break; // Якщо немає більше даних, припиняємо цикл
            }

            // Зупиняємо запити після досягнення максимального числа сторінок
        } while (response.data.data.length === pageSize && page <= maxPages);

        return allItems;
    } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        throw error;
    }
};
