import React, { Component } from 'react';
import './errorboundary.scss'; // Стили для сообщения об ошибке

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Обновляет состояние так, чтобы при следующем рендере показался запасной UI
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Можно сохранить информацию об ошибке для логирования
    this.setState({ errorInfo });
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Отрисовка резервного UI в виде модального окна
      return (
        <>
          <div className="error-boundary-overlay" />
          <div className="error-boundary">
            <h1>Something went wrong.
            </h1>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo?.componentStack}
            </details>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
