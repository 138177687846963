import axios from 'axios';
import { BASE_URL } from './baseURL';
import {toast} from "react-toastify";
const getHeaders = () => {
    return {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    };
};

export const getBlogs = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/blogs`, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error fetching collection items:', error);
        return [];
    }
};

export const createBlogPost = async (data) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/blog`, data, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error creating blog post:', error.message);
    }
};

export const getDisableCollectionItems = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/api/disable/collection_items`, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error fetching disabled collection items:', error.message);
    }
};

export const getCollectionItems = async (page = 1) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/collection/collection_items?page=${page}`, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error fetching collection items:', error.message);
    }
};

export const getCollectionItemById = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/collection/collection_items/${id}`, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error fetching collection item by ID:', error.message);
    }
};

export const getCollectionById = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/collection/${id}`, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error fetching collection by ID:', error);
        return [];
    }
};

export const DisableCollectionItemById = async (id, body) => {
    try {
        const response = await axios.patch(`${BASE_URL}/api/visibility/collection_item/${id}`, body, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error disabling collection item by ID:', error.message);
    }
};

export const DisableCollectionById = async (id, body) => {
    try {
        const response = await axios.patch(`${BASE_URL}/api/visibility/collection/${id}`, body, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error disabling collection by ID:', error.message);
    }
};

export const DisableNftItemById = async (id, body) => {
    try {
        const response = await axios.patch(`${BASE_URL}/api/visibility/nft/${id}`, body, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error disabling NFT item by ID:', error.message);
    }
};

export const getModerationCollectionItem = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/moderation/collection-item/${id}`, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error fetching moderation collection item:', error.message);
    }
};

export const getModerationNFT = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/moderation/nft/${id}`, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error fetching moderation NFT:', error.message);
    }
};

export const approveNFTWithoutCollection = async (nftId, status) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/moderation/nft/${nftId}`, status, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error approving NFT without collection:', error);
    }
};

export const approveNFTWithCollection = async (nftId, status) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/moderation/collection-item/${nftId}`, status, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error approving NFT with collection:', error);
    }
};

export const rejectNFTWithoutCollection = async (nftId, status) => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/moderation/reject/nft/${nftId}`, { headers: getHeaders(), data: status });
        return response.data;
    } catch (error) {
        console.error('Error rejecting NFT without collection:', error);
    }
};

export const rejectNFTWithCollection = async (nftId, status) => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/moderation/reject/collection-item/${nftId}`, { headers: getHeaders(), data: status });
        return response.data;
    } catch (error) {
        console.error('Error rejecting NFT with collection:', error);
    }
};

export const getModerationCollection = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/api/moderation/collection/${id}`, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error fetching moderation collection by ID:', error.message);
    }
};

export const approveCollectionReq = async (collectionId, status) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/moderation/collection/${collectionId}`, status, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error approving collection:', error);
    }
};

export const RejectCollectionReq = async (collectionId) => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/moderation/reject/collection/${collectionId}?status=reject`, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error rejecting collection:', error);
    }
};

export const ChangeCollectionPropertyById = async (collectionId, data) => {
    try {
        const response = await axios.post(`${BASE_URL}/api/moderation/collection/${collectionId}`, data, { headers: getHeaders() });
        return response.data;
    } catch (error) {
        console.error('Error updating collection property:', error);
    }
};

export const deleteBlogById = async (blogId) => {
    try {
        const response = await axios.delete(`${BASE_URL}/api/delete/blog/${blogId}`,{ headers: getHeaders() });
        toast.success('Blog is deleted');
        return response.data;
        
    } catch (error) {
        toast.error('Blog is not deleted');
        console.error('Error deleting blog:', error);
    }
};
