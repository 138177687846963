import * as React from 'react';
import noImage from "../../Assets/images/no-image.jpg"
import './nftitem.scss'
import ModalListNFT from '../ModalListNft/ModalListNFT';

export default function NFTItem ({ nft, onClick,type }) {


    const handleItemClick = () => {
        onClick(nft,type);
    };

    return (
        <div className='nftItem'>
            <div className="nftItem__container">
                    <ModalListNFT nft={nft}  imageSrc={nft.image?.previewUrl || nft.avatar?.previewUrl || noImage} altText="X Image" onClick={handleItemClick} />
            </div>
        </div>
    );
}
